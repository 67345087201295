import React from 'react';

export function EmailIcon2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='16'
      className={props.className}
      viewBox='0 0 22 16'
    >
      <path d='M2.629 15.084h16.744c.924 0 1.68-.755 1.68-1.68l-.001-10.808c0-.392-.14-.756-.364-1.065a1.723 1.723 0 00-1.289-.615H2.63c-.532 0-.98.251-1.289.615-.224.28-.364.645-.364 1.065V13.43c-.028.897.728 1.653 1.653 1.653zm17.22-2.435l-5.685-5.32 5.685-4.425v9.744zm-8.037-4.985a1.344 1.344 0 01-1.624 0l-7.14-5.572H18.98l-7.168 5.572zm-4.116-.42L2.152 12.62V2.904l5.544 4.34zM2.629 13.91h-.085l6.105-5.91.811.617c.449.335.98.504 1.54.504s1.092-.168 1.54-.532l.672-.504 6.215 5.796H2.63v.028z'></path>
    </svg>
  );
}

export function ThickLineEmailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      className={`${props.className ?? 'w-5 h-5 fill-current'}`}
    >
      <path
        fillRule='evenodd'
        d='M1.204 2.818c-.64 0-1.144.569-1.144 1.205v11.954c0 .636.504 1.205 1.144 1.205h17.594c.64 0 1.144-.569 1.144-1.205V4.023c0-.637-.504-1.205-1.144-1.205H1.204zM10 11.006L3.21 4.769h13.58L10 11.006zm-8.082 2.787V6.162l3.834 3.524-3.834 4.108zm16.164 0l-3.835-4.108 3.835-3.524v7.633zm-14.9 1.439l3.97-4.255 2.24 2.05v.001a.904.904 0 00.608.237c.226 0 .44-.085.608-.237l2.24-2.051 3.97 4.255H3.184z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
